import React, { useEffect, useRef, useState } from "react";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import TGIcon from "../../../../../../shared/tg-icon";
import styles from "./personal-info.module.scss";
import mealData from "../../../../../../../public/json/meal.json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import {
  fetchMyProfileRequest,
  fetchCRMNatinalityRequest,
} from "../../../../slice/fetchMainMemberProfileSlice";
import moment from "moment";
import { t } from "i18next";
import gsap from "gsap";
import TGSelection from "../../../../../../shared/tg-selection";
import TGInput from "../../../../../../shared/tg-input";
import DatePickerCalendar from "../../../../../../shared/tg-calendar";
import TGCheckBox from "../../../../../../shared/tg-checkbox";
import TGDateDropdown from "../../../../../../shared/tg-date-dropdown";
import { setProfileIsEditable } from "../../../../slice/profileContactInfoSlice";
import { Form, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  Country,
  Preference,
  ProfilePersonalInfo,
} from "../../../../models/models";
import { Typeahead } from "react-bootstrap-typeahead";
import termConditionData from "../../../../../../../public/json/termConditionsProfileComponents.json";
// import countryData from "../../../../../../../public/json/en-th/country-nationality-code-flag.json";
import i18n from "i18next";
import TGModal from "../../../../../../shared/tg-modal";
import {
  getProfilePersonalInfoPending,
  getCrmProfilePersonalInfoPending,
  setIsPersonalInfoEditable,
  getMyDeleteAccTermsSuccess,
  getMyDeleteAccTermsFailure,
} from "../../../../slice/profilePersonalInfoSlice";
import { camelCase } from "../../../../../../utils/helper";
import { fetchCrmMemberProfileRequest } from "../../../../slice/fetchCrmProfileSlice";
import { fetchCountryRequest } from "../../../../slice/fetchProfileSlice";
import { config } from "../../../../../../config/global";
import TGTypeahead from "../../../../../../shared/tg-typeahead";
import { fetchCRMresidenceRequest, fetchCRMnewsletterPreferenceRequest } from "../../../../slice/fetchCrmProfileSlice";
interface Props {
  profileName?: string;
}
gsap.registerPlugin();
export const PersonalInfo = (props: Props) => {
  const location = useLocation();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;

  const [profilePersonalInfoData, setProfilePersonalInfoData] =
    useState<ProfilePersonalInfo>({
      emailAddress: "",
      updateMainProfileFlag: "Y",
      language: "",
      updateSSRFlag: "Y",
      ssr: {
        oldSeat: "",
        newSeat: "",
        oldMeal: "",
        newMeal: "",
      },
      nationality: "",
      gender: "",
      updateCommunicationFlag: "Y",
      thaiOfferIndicator: "",
      partnerOfferIndicator: "",
      consent: {
        consentFlag: "",
        consentParentName: "",
      },
      birthDate: "",
      memberId: "",
      consentParentFirstName: "",
      consentParentLastName: "",
      title: "",
    });
  const [errors, setErrors] = useState<Partial<ProfilePersonalInfo>>({});
  const [crmErrors, setCrmErrors] = useState<Partial<ProfilePersonalInfo>>({});
  const [isToggleOpen, setIsToggleOpen] = useState<string>("");
  const [newsletterPreferenceResponse, setNewsletterPreferenceResponse ] = useState<any>([]);
  const [residenceResponse, setResidenceResponse ] = useState<any>([]);
  const isEditable = useSelector(
    (state: RootState) => state?.osciReducer?.profilePersonalInfo.isEditable
  );
  const profileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.MyProfileDeails.myprofileData
  );
  const crmNationalityResponse = useSelector(
    (state: RootState) => state?.osciReducer?.MyProfileDeails.crmNationalityData
  );

  useEffect(() => {
    setCrmNationalityList(crmNationalityResponse.nationality);
  }, [crmNationalityResponse]);

  const fullName =
    profileResponse?.firstName + " " + profileResponse?.middleName;
  useEffect(() => {
    let updatedNationality: any = countryData?.filter(
      (x: any) => x.code === profileResponse?.nationality
    );
    setProfilePersonalInfoData({
      ...profilePersonalInfoData,
      emailAddress: profileResponse?.email,
      language: profileResponse?.language,
      ssr: {
        ...profilePersonalInfoData.ssr,
        oldSeat: profileResponse?.ssr?.[0].ssrSeat,
        newSeat: profileResponse?.ssr?.[0].ssrSeat,
        oldMeal: profileResponse?.ssr?.[0].ssrMeal,
        newMeal: profileResponse?.ssr?.[0].ssrMeal,
      },
      nationality: updatedNationality?.[0],
      thaiOfferIndicator: profileResponse?.thaiOfferIndicator,
      partnerOfferIndicator: profileResponse?.partnerOfferIndicator,
    });
  }, [profileResponse]);
  const [selectedPreferedCountry, setSelectedPreferedCountry] =
  useState<Country | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [formattedSaluttation, setFormattedSaluttation] = useState("");
  const [formattedCrmSaluttation, setFormattedCrmSaluttation] = useState("");
  const [formattedDob, setFormattedDob] = useState("");
  const [formattedDobView, setFormattedDobView] = useState("");
  const [formattedCrmDob, setFormattedCrmDob] = useState("");
  const [formattedCRMDobView, setFormattedCRMDobView] = useState("");
  const [crmLanguage, setCrmLanguage] = useState("English");
  const [formattedData, setFormattedData] = useState({ dob: "", gender: "" });
  const [formattedLanguage, setFormattedLanguage] = useState("");
  const [isPrimaryChecked, setIsPrimaryChecked] = useState<boolean>(false);
  const [isSecondaryChecked, setIsSecondaryChecked] = useState<boolean>(false);
  const [isCrmSubscribed, setIsCrmSubscribed] = useState<boolean>(false);
  const [isProfile, setIsProfile] = useState(false);
  // const [isEditable, setIsEditable] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [defaultPreference, setDefaultPreference] = useState<Preference>({
    seat: "No Preference",
    meal: "No Preference",
    language: "English",
  });
  const [mealPreference, setmealPreference] = useState("No Preference");
  const [seatPreference, setSeatPreference] = useState("No Preference");
  const [isPreferenceDisabled, setIsPreferenceDisabled] = useState(true);
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [selectedNationalityValue, setSelectedNationalityValue] =
    useState(null);
  const [selectedCrmNationalityValue, setSelectedCrmNationalityValue] =
    useState(null);
  const [selectPreferenceCountry, setSelectPreferencecCountry] =
    useState<Country | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [nationality, setNationality] = useState("");
  const [crmNationalityList, setCrmNationalityList] = useState([]);
  const [isCrm, setIsCrm] = useState(false);
  const [crmProfileData, setCrmProfileData] = useState<
    Partial<ProfilePersonalInfo>
  >({});
  const [isLessThanSixteen, setIsLessThanSixteen] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const termConditionRef = useRef<any>(null);
  const [selectedCntryValue, setSelectedCntryValue] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  // const preferenceFromResponse = useSelector(
  //   (state: RootState) => state?.osciReducer?.preferenceFrom.countryList
  // );
  const profPersonalInfoSubmitResp = useSelector(
    (state: RootState) => state?.osciReducer?.profilePersonalInfo
  );
  const crmProfileResponse = useSelector(
    (state: RootState) =>
      state?.osciReducer?.fetchCrmMemberProfile.crmMemberprofileData
  );
  const deleteAccountPopUP = useSelector(
    (state: RootState) =>
      state?.osciReducer?.profilePersonalInfo?.myDeleteAccTerms
  );

  const countryResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.countryData
  );
  useEffect(() => {
    dispatch(fetchCountryRequest());
    dispatch(fetchCRMnewsletterPreferenceRequest());
    dispatch(fetchCRMresidenceRequest());
  }, [dispatch]);

  useEffect(() => {
    setCountryData(countryResponse);
  }, [countryResponse]);

const crmProfileResponses = useSelector(
  (state: RootState) => state.osciReducer?.fetchCrmMemberProfile
);

useEffect(() => {
  setNewsletterPreferenceResponse(crmProfileResponses?.crmnewsletterPreferenceData),
  setResidenceResponse(crmProfileResponses?.crmresidenceData)
}, [crmProfileResponses]);

  const [consentFlag, setConsentFlag] = useState(false);

  const DELETE_ACC_TERMS_URL = config.TEAMSITE_DELETE_ACCOUNT_TERMS;

  const [deletePopUp, setDeletePopUP] = useState(false);
  const deleteAccountHandler = () => {
    setDeletePopUP(true);
  };
  const deletePopUpCloseHandle = () => {
    setDeletePopUP(false);
  };
  const proceedDeleteAccountHandler = () => {
    window.location.href =
      `${process.env.REACT_APP_ROP_MAIL}?subject=${t(
        "label_membership_cancellation"
      )}` || "";
  };

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: DELETE_ACC_TERMS_URL,
      isTeamsite: true,
      successAction: getMyDeleteAccTermsSuccess,
      errorAction: getMyDeleteAccTermsFailure,
    });
  }, [dispatch]);

  useEffect(() => {
    props.profileName === "CRM" && setIsCrm(true);
  }, [props.profileName]);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       const data = {
  //         mode: "no-cors",
  //         headers: {
  //           Authorization: sessionStorage.getItem("accesstoken"),
  //         },
  //       };
  //       const FETCH_CRM_NATIONALITY = config.CRM_NATIONALITY_API;
  // console.log("hi")
  //       try {
  //         const response = await api.get(FETCH_CRM_NATIONALITY, data);
  //         debugger;
  //         // const result = await response.json();
  //         console.log(response, "result");
  //         debugger
  //         setCrmNationalityList(response.data.nationality);
  //       } catch (error) {
  //         console.log("error");
  //       }
  //     };

  //     fetchData();
  //   }, []);

  useEffect(() => {
    if (profPersonalInfoSubmitResp.isUpdateSuccess === true) {
      if (props.profileName === "CRM") {
        dispatch(fetchCrmMemberProfileRequest());
      } else {
        dispatch(fetchMyProfileRequest());
      }
      dispatch(setIsPersonalInfoEditable(false));
      setIsChecked(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [profPersonalInfoSubmitResp.isUpdateSuccess]);

  const profileScrollRef = useRef<any>(null);
  useEffect(() => {
    if (window?.sessionStorage.getItem("isROPLoggedIn") == "true") {
      dispatch(fetchMyProfileRequest());
    } else {
      dispatch(fetchCRMNatinalityRequest());
      dispatch(fetchCrmMemberProfileRequest());
      setIsCrm(true);
    }
  }, []);
  useEffect(() => {
    if (crmProfileResponse) {
      const formattedDateView = moment(crmProfileResponse?.dob).format(
        "DD MMMM YYYY"
      );
      const formattedDate = moment(crmProfileResponse?.dob).format(
        "YYYY-MMMM-DD"
      );

      let salutation;
      if (
        crmProfileResponse?.salutation == "Mr." ||
        crmProfileResponse?.salutation == "Mrs." ||
        crmProfileResponse?.salutation == "Ms."
      ) {
        salutation = crmProfileResponse?.salutation?.slice(0, -1);
      } else {
        salutation = crmProfileResponse?.salutation;
      }
      setFormattedCrmSaluttation(salutation);

      setFormattedCRMDobView(formattedDateView);
      setFormattedCrmDob(formattedDate);
      setIsCrmSubscribed(crmProfileResponse?.subcription);
      if (crmProfileResponse.language) {
        setCrmLanguage(crmProfileResponse.language);
      }
    }
  }, [crmProfileResponse]);

  var res: any;
  var cntry: any;

  useEffect(() => {
    const newspreffered = crmProfileResponse.preferredNews
    const countryResid = crmProfileResponse.country
   
    if (newsletterPreferenceResponse?.length > 0) {
      res = newsletterPreferenceResponse.find(
        (el: { country: string }) => el.country === newspreffered
      );
      if (residenceResponse?.length > 0) {
        cntry = residenceResponse.find(
       
          (el: { name: string }) => el.name === countryResid
        );
      
        setSelectedCntryValue(cntry);
      }
      setSelectedCountryValue(res);
    }
  

  }, [newsletterPreferenceResponse,residenceResponse, crmProfileResponse]);

  const { isLoading } = isCrm
    ? useSelector(
        (state: RootState) => state?.osciReducer?.fetchCrmMemberProfile
      )
    : useSelector((state: RootState) => state?.osciReducer?.MyProfileDeails);

  const handlePreferredCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryValue(country);
  };
  const handleKeyDown = (e: any) => {
    const isValidInput = /^[a-zA-Z ]{0,100}$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  };
  const handleNationalityChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedNationalityValue(country);
    if (isCrm) {
      setCrmProfileData({
        ...crmProfileData,
        nationality: country,
      });
    } else {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        nationality: country,
      });
    }
    setErrors({ ...errors, nationality: "" });
    setCrmErrors({ ...crmErrors, nationality: "" });
    setIsToggleOpen("");
  };

  useEffect(() => {
    if (profileResponse.nationality) {
      setErrors({ ...errors, nationality: "" });
    } else {
      setErrors({ ...errors, nationality: "error" });
    }
  }, [profileResponse]);

  const [lastScroll, setLastScroll] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const headerScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(headerScroll - lastScroll) > 10) {
        // to check the scroll to prevent minor jumps
        if (headerScroll > 1079 && headerScroll > lastScroll) {
          if (headerScroll > 1000) {
            //  to check the header to pass the some distance to start the animation
            setIsProfile(true);
          }
          setIsProfile(true);
          gsap.to(profileScrollRef.current, { y: "100%", duration: 0.25 });
        }
        if (headerScroll < 1076 || headerScroll < lastScroll) {
          gsap.to(profileScrollRef.current, { y: "0%", duration: 0.25 });
          if (headerScroll < 1018) {
            //  header sets to relative when screen reaches 300px or less
            setIsProfile(false);
          }
        }
        setLastScroll(headerScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScroll]);

  const onSelect = (e: any, obj: any) => {
    const { id } = e.target;
    const { name, value } = e.target;
  };
  const setSeatState = (e: any, obj: any) => {
    setProfilePersonalInfoData({
      ...profilePersonalInfoData,
      ssr: {
        ...profilePersonalInfoData.ssr,
        newSeat: obj.listAbbr,
      },
    });
  };
  const setMealState = (e: any, obj: any) => {
    setProfilePersonalInfoData({
      ...profilePersonalInfoData,
      ssr: {
        ...profilePersonalInfoData.ssr,
        newMeal: obj.listAbbr,
      },
    });
  };
  const checkedPrimaryChange = (e: any): void => {
    setIsPrimaryChecked((prevState: any) => !prevState);
  };

  //For Marketting Preference
  useEffect(() => {
    if (isPrimaryChecked) {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        thaiOfferIndicator: "D",
      });
      setIsPreferenceDisabled(false);
      setConsentFlag(true);
    } else {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        thaiOfferIndicator: "N",
      });
      setIsPreferenceDisabled(true);
      setConsentFlag(false);
    }
  }, [isPrimaryChecked]);
  useEffect(() => {
    if (isSecondaryChecked) {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        partnerOfferIndicator: "D",
      });
      setConsentFlag(true);
    } else {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        partnerOfferIndicator: "N",
      });
      setConsentFlag(false);
    }
  }, [isSecondaryChecked]);
  const checkedSecondaryChange = (e: any): void => {
    setIsSecondaryChecked((prevState) => !prevState);
  };

  const checkedCrmChange = (e: any): void => {
    setIsCrmSubscribed((prevState) => !prevState);
  };

  useEffect(() => {
    setCrmProfileData({
      ...crmProfileData,
      subscription: isCrmSubscribed,
    });
  }, [isCrmSubscribed]);

  const setTitleState = (e: any, obj: any) => {
    isCrm &&
      setCrmProfileData({
        ...crmProfileData,
        title: obj.listName,
      });
    setCrmErrors({ ...crmErrors, title: "" });
  };
  const onChange = (e: any, label: any) => {
    const formattedDate = moment(e).format("YYYY-MM-DD");
  };

  useEffect(() => {
    dispatch(setIsPersonalInfoEditable(false));
  }, []);
  const handleEditClick = () => {
    dispatch(setIsPersonalInfoEditable(true));
  };
  const handleNationality = (obj: any) => {
    setNationality(obj);
    setProfilePersonalInfoData({
      ...profilePersonalInfoData,
      nationality: obj?.[0],
    });
    if (
      profilePersonalInfoData.nationality == "" ||
      profilePersonalInfoData.nationality == undefined
    ) {
      setErrors({ ...errors, nationality: "" });
    } else {
      setErrors({ ...errors, [nationality]: "error" });
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name !== "mealPreference" || name !== "seatPreference") {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        [name]: value,
      });
    } else if (name === "seatPreference") {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        ssr: {
          ...profilePersonalInfoData.ssr,
          newSeat: value,
        },
      });
    } else if (name === "mealPreference") {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        ssr: {
          ...profilePersonalInfoData.ssr,
          newSeat: value,
        },
      });
    } else if (name === "") {
      setProfilePersonalInfoData({
        ...profilePersonalInfoData,
        consent: {
          ...profilePersonalInfoData.consent,
          consentFlag: value,
          consentParentName: value,
        },
      });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;

      if (value == true) {
        isCrm
          ? setCrmErrors({ ...crmErrors, termsConditions: "" })
          : setErrors({ ...errors, termsConditions: "" });
      } else {
        isCrm
          ? setCrmErrors({ ...crmErrors, termsConditions: "error" })
          : setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };

  const handleDateChange = (date: string, month: string, year: string) => {
    const formattedDob = `${year}-${month}-${date}`;
  };
  const setGenderState = (e: any, obj: any) => {
    isCrm &&
      setCrmProfileData({
        ...crmProfileData,
        gender: obj.listName,
      });
    setCrmErrors({ ...crmErrors, gender: "" });
  };
  const setLanguageState = (e: any, obj: any) => {
    isCrm
      ? setCrmProfileData({
          ...crmProfileData,
          language: obj.listName,
        })
      : setProfilePersonalInfoData({
          ...profilePersonalInfoData,
          language: obj.listAbbr,
        });
  };
  useEffect(() => {
    if (profileResponse) {
      if (profileResponse.gender == "M") {
        setFormattedData({ ...formattedData, gender: "Male" });
      } else {
        setFormattedData({ ...formattedData, gender: "Female" });
      }

      const formattedDate = moment(profileResponse?.birthDate).format(
        "YYYY-MMMM-DD"
      );
      const formattedDateView = moment(profileResponse?.birthDate).format(
        "DD MMMM YYYY"
      );
      setFormattedDobView(formattedDateView);
      setFormattedDob(formattedDate);

      if (profileResponse.language == "E") {
        setFormattedLanguage("ENGLISH");
      } else {
        setFormattedLanguage("THAI");
      }
      if (profileResponse?.thaiOfferIndicator == "D") {
        setIsPrimaryChecked(true);
      } else {
        setIsPrimaryChecked(false);
      }
      if (profileResponse?.partnerOfferIndicator == "D") {
        setIsSecondaryChecked(true);
      } else {
        setIsSecondaryChecked(false);
      }
      const cntry = countryData.find(
        (el: { code: string }) => el.code === profileResponse?.nationality
      );
      setNationality(cntry);
    }
  }, [profileResponse]);

 

  // useEffect(() => {
  //   const urlCountry = i18n.language?.split("-")?.[1]?.toUpperCase();

  //   res = preferenceFromResponse.find(
  //     (el: { code: string }) => el.code === urlCountry
  //   );
  //   if (res !== null && res !== undefined) {
  //     setSelectedCountryValue(res);
  //   }
  // }, [preferenceFromResponse]);

  const seatData = [
    { id: 1, listName: "Aisle", listAbbr: "NSSA" },
    { id: 2, listName: "Window", listAbbr: "NSSW" },
    { id: 3, listName: "No Preference", listAbbr: "" },
  ];

  //parent gauardien consent form for CRM and ROP
  useEffect(() => {
    if (props.profileName === "ROP") {
      const dobDate = moment(formattedDob);
      let lessThan16 = moment().subtract(16, "years");

      dobDate?.isAfter(lessThan16)
        ? setIsLessThanSixteen(true)
        : setIsLessThanSixteen(false);
    }
  }, [
    profileResponse,
    isLessThanSixteen,
    isPrimaryChecked,
    isSecondaryChecked,
    formattedDob,
    props.profileName,
  ]);

  useEffect(() => {
    if (profileResponse?.salutation) {
      let salutation;
      if (
        profileResponse?.salutation == "Mr." ||
        profileResponse?.salutation == "Mrs." ||
        profileResponse?.salutation == "Ms."
      ) {
        salutation = profileResponse?.salutation?.slice(0, -1).toUpperCase();
      } else {
        salutation = profileResponse?.salutation.toUpperCase();
      }
      setFormattedSaluttation(salutation);
    }
    if (
      profileResponse?.ssr?.[0].ssrSeat == "" ||
      profileResponse?.ssr?.[0].ssrSeat == undefined
    ) {
      setSeatPreference("No Preference");
    } else {
      const formattedSeat = seatData
        .filter((el) => el.listAbbr === profileResponse?.ssr?.[0].ssrSeat)
        .find((listName) => listName !== undefined)
        ?.listName.toUpperCase();
      setSeatPreference(formattedSeat);
    }
    if (
      profileResponse?.ssr?.[0].ssrMeal == "" ||
      profileResponse?.ssr?.[0].ssrMeal == undefined
    ) {
      setmealPreference("No preference");
    } else {
      // const newFormattedMeal = mealData.filter(el=> el.subItem.some(el=> el.listAbbr ===  profileResponse?.ssr?.[0].ssrMeal))
      const formattedMeal: any = mealData
        .map((el) =>
          el.subItem.find(
            (el) => el.listAbbr === profileResponse?.ssr?.[0].ssrMeal
          )
        )
        .find((listName) => listName !== undefined)?.listName;

      setmealPreference(formattedMeal?.toUpperCase());
    }
  }, [profileResponse]);

  const handlePrefCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryValue(country);
     setErrors({ ...errors, preference: "" });
    setIsToggleOpen("");
    setCrmProfileData({
      ...crmProfileData,
      preferredNews: country.country,
    })
    country
  };
  const handleCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCntryValue(country);
   setCrmProfileData({
        ...crmProfileData,
        country: country.name,
      })
    setErrors({ ...errors, country: "" });
    setIsToggleOpen("");
  };

  // Save API Params
  const handlePersonalInfoUpdate = () => {
    if (isCrm) {
      let newError = {};

      if (isChecked == false) {
        newError = { ...newError, termsConditions: "error" };
      }
      if (isChecked) {
        newError = { ...newError, termsConditions: "" };
      }
      if (crmProfileResponse?.nationality || crmProfileData?.nationality) {
        newError = { ...newError, nationality: "" };
      } else {
        newError = { ...newError, nationality: "error" };
      }
      if (crmProfileResponse?.gender || crmProfileData?.gender) {
        newError = { ...newError, gender: "" };
      } else {
        newError = { ...newError, gender: "error" };
      }

      const dobDate = moment(crmProfileResponse.dob, "YYYY-MM-DD");
      const lessThan12 = moment().subtract(12, "years");

      if (
        dobDate.isAfter(lessThan12) &&
        (crmProfileData.title == t("enroll_mr") ||
          crmProfileData.title == t("enroll_mrs") ||
          crmProfileData.title == t("enroll_ms"))
      ) {
        newError = { ...newError, title: t("enroll_title_error") };
      }
      if (
        dobDate?.isBefore(lessThan12) &&
        (crmProfileData.title == t("enroll_miss") ||
          crmProfileData.title == t("enroll_mister"))
      ) {
        newError = { ...newError, title: t("enroll_adult_greater_12") };
      }

      if (
        (crmProfileData.gender !== "" && crmProfileData.gender !== undefined) ||
        (crmProfileResponse?.gender !== "" &&
          crmProfileResponse?.gender !== undefined)
      ) {
        const genderTitleMap: { [key: string]: string[] } = {
          Male: [t("enroll_mr"), t("enroll_mister")],
          Female: [t("enroll_mrs"), t("enroll_ms"), t("enroll_miss")],
          Unspecified: [t("enroll_title_prefer_not")],
          Undisclosed: [t("enroll_title_prefer_not")],
        };

        const validTitle =
          crmProfileData.gender !== "" && crmProfileData.gender !== undefined
            ? genderTitleMap[crmProfileData.gender]
            : genderTitleMap[crmProfileResponse?.gender];

        if (crmProfileData.title !== "" && crmProfileData.title !== undefined) {
          if (!validTitle || !validTitle.includes(crmProfileData.title)) {
            newError = { ...newError, gender: "error" };
          } else {
            newError = { ...newError, gender: "" };
          }
        } else {
          if (
            !validTitle ||
            !validTitle.includes(crmProfileResponse?.salutation)
          ) {
            newError = { ...newError, gender: "error" };
          } else {
            newError = { ...newError, gender: "" };
          }
        }
      }

      setCrmErrors({ ...newError });

      const newCrmErrors: Partial<ProfilePersonalInfo> = {};

      if (
        newError.termsConditions == undefined ||
        newError.termsConditions == "error"
      ) {
        newCrmErrors["termsConditions"] = "error";
      }

      if (
        newError.nationality == undefined ||
        newError.nationality == "error"
      ) {
        crmProfileResponse?.nationality
          ? { ...newCrmErrors }
          : (newCrmErrors["nationality"] = "error");
      }
      if (newError.gender == undefined || newError.gender == "error") {
        crmProfileResponse?.gender && newError.gender !== "error"
          ? { ...newCrmErrors }
          : (newCrmErrors["gender"] = "error");
      }
      if (
        newError?.title == t("enroll_title_error") ||
        newError?.title == t("enroll_adult_greater_12")
      ) {
        newCrmErrors["title"] = "error";
      }

      var filterCrmUndefinedError;
      const keys = Object.keys(newCrmErrors) as (keyof ProfilePersonalInfo)[];
      filterCrmUndefinedError = keys.filter(
        (key) => newCrmErrors[key] !== undefined
      );

      if (filterCrmUndefinedError?.length === 0 && isChecked) {
        dispatch(
          getCrmProfilePersonalInfoPending({
            ...crmProfileData,
          })
        );
      }
    } else {
      const newErrors: Partial<ProfilePersonalInfo> = {};

      if (isLessThanSixteen) {
        if (profilePersonalInfoData?.consentParentFirstName.trim() === "") {
          setErrors({ ...newErrors, consentParentFirstName: "error" });
          newErrors["consentParentFirstName"] = "error";
        } else if (
          profilePersonalInfoData?.consentParentFirstName?.length < 2
        ) {
          setErrors({ ...newErrors, consentParentFirstName: "error" });
          newErrors["consentParentFirstName"] = "error";
        }

        if (profilePersonalInfoData?.consentParentLastName.trim() === "") {
          setErrors({ ...newErrors, consentParentLastName: "error" });
          newErrors["consentParentLastName"] = "error";
        } else if (profilePersonalInfoData?.consentParentLastName?.length < 2) {
          setErrors({ ...newErrors, consentParentLastName: "error" });
          newErrors["consentParentLastName"] = "error";
        }
      }

      if (isChecked == false) {
        setErrors({ ...newErrors, termsConditions: "error" });
        newErrors["termsConditions"] = "error";
      }

      // if (
      //   errors.termsConditions == undefined ||
      //   errors.termsConditions == "error"
      // ) {
      //   setErrors({ ...newErrors, termsConditions: "error" });
      //   newErrors["termsConditions"] = "error";
      // }
      if (
        nationality?.nationality == "" ||
        nationality?.nationality == undefined
      ) {
        if (errors.nationality == undefined || errors.nationality == "error") {
          setErrors({ ...newErrors, nationality: "error" });
          newErrors["nationality"] = "error";
        }
      }
      var filterUndefinedError;
      const keys = Object.keys(newErrors) as (keyof ProfilePersonalInfo)[];
      filterUndefinedError = keys.filter((key) => newErrors[key] !== undefined);
      if (filterUndefinedError.length === 0) {
        dispatch(
          getProfilePersonalInfoPending({
            emailAddress: profilePersonalInfoData.emailAddress,
            updateMainProfileFlag:
              profilePersonalInfoData.updateMainProfileFlag,
            language: profilePersonalInfoData.language,
            updateSSRFlag: profilePersonalInfoData.updateSSRFlag,
            ssr: profilePersonalInfoData.ssr,
            nationality: profilePersonalInfoData.nationality?.code,
            updateCommunicationFlag:
              profilePersonalInfoData.updateCommunicationFlag,
            thaiOfferIndicator: profilePersonalInfoData.thaiOfferIndicator,
            partnerOfferIndicator:
              profilePersonalInfoData.partnerOfferIndicator,
            birthDate: profileResponse?.birthDate,
            memberId: profileResponse?.memberID,
            consent: {
              consentFlag: consentFlag,
              consentParentName:
                profilePersonalInfoData.consentParentFirstName +
                " " +
                profilePersonalInfoData.consentParentLastName,
            },
          })
        );
      }
    }
  };

  const renderSaveButton = () => {
    return (
      <TGButtonVariants
        label={profPersonalInfoSubmitResp.isLoading ? "" : "Save"}
        disabled={profPersonalInfoSubmitResp.isLoading}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="30px"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        bgColor="#684B9B"
        padding="16px"
        textColor="#FFFFFF"
        fontFamily="Inter"
        fontSize="16px"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="160%"
        borderDisabled="0.791px solid #E3D4FE"
        bgColorDisabled="#E3D4FE"
        boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        bgColorHover="#684b9b"
        borderHover="0.791px solid #E3D4FE"
        boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        height="50px"
        width="100%"
        onClick={handlePersonalInfoUpdate}
      >
        {profPersonalInfoSubmitResp.isLoading ? (
          <>
            <Spinner
              animation="grow"
              variant="light"
              style={{
                width: "10px",
                height: "10px",
                margin: "5px",
              }}
            />
            <Spinner
              animation="grow"
              variant="light"
              style={{
                width: "10px",
                height: "10px",
                margin: "5px",
              }}
            />
            <Spinner
              animation="grow"
              variant="light"
              style={{
                width: "10px",
                height: "10px",
                margin: "5px",
              }}
            />
          </>
        ) : null}
      </TGButtonVariants>
    );
  };

  const renderTermsConditionError = () => {
    return (
      <div className={styles.lifeStyleInfoTermsConditionsError}>
        <TGIcon icon="error-icon" fillColor="" size="16" />
        <div className={styles.lifeStyleInfoTermConditionText}>
          {t("enroll_terms_condition")}
        </div>
      </div>
    );
  };
  return (
    <>
      {!isEditable && !isLoading && (
        <>
          <div className={styles.headingContainer}>
            <div className={styles.topic}>{t("enroll_personal_Info")}</div>
            {isDesktopView && (
              <div
                onClick={handleEditClick}
                className={styles.profileContactInfoHeadingButtonContainer}
              >
                <div className={styles.profileContactInfoHeadingButton}>
                  <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
                  <span className={styles.editButtonText}>
                    {t("label_edit")}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={styles.content}>
            <div className={styles.myDetails}>
              <div className={styles.detailsContainer}>
                <div className={styles.detailsInnerContainer}>
                  <span className={styles.detailText}>
                    {t("profile_my_Details")}
                  </span>
                </div>
                <div className={styles.detailsInnerContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("enroll_title")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {isCrm ? formattedCrmSaluttation : formattedSaluttation}
                    </div>
                  </div>
                </div>
                <div className={styles.detailsInnerContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("label_reset_first_name")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {isCrm
                        ? crmProfileResponse?.firstName
                          ? `${crmProfileResponse.firstName} ${
                              crmProfileResponse?.middleName
                                ? crmProfileResponse?.middleName
                                : ""
                            }`?.toUpperCase()
                          : "-"
                        : profileResponse.firstName
                        ? `${profileResponse.firstName} ${
                            profileResponse?.middleName
                              ? profileResponse?.middleName
                              : ""
                          }`
                        : "-"}
                    </div>
                  </div>
                  {/* <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {isCrm
                        ? t("profile_middle_name")
                        : t("profile_middleName")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {isCrm
                        ? crmProfileResponse?.middleName
                          ? crmProfileResponse.middleName?.toUpperCase()
                          : "-"
                        : profileResponse.middleName
                        ? camelCase(profileResponse.middleName)
                        : "-"}
                    </div>
                  </div> */}
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("label_manage_last_name")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {isCrm
                        ? crmProfileResponse?.lastName
                          ? crmProfileResponse.lastName?.toUpperCase()
                          : "-"
                        : profileResponse.lastName
                        ? profileResponse.lastName
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    !isDesktopView && isCrm
                      ? styles.detailsInnerContainer
                      : styles.detailsInnerContainer1
                  }
                >
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("label_reset_dob")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {isCrm ? formattedCRMDobView : formattedDobView}
                    </div>
                  </div>
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("enroll_gender")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {isCrm
                        ? crmProfileResponse?.gender
                          ? camelCase(crmProfileResponse.gender)
                          : "-"
                        : profileResponse.gender == "F"
                        ? "FEMALE"
                        : profileResponse.gender == "M"
                        ? "MALE"
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.detailsInnerContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("profile_nationality")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {isCrm
                        ? crmProfileResponse?.nationality
                          ? camelCase(crmProfileResponse.nationality)
                          : "-"
                        : profilePersonalInfoData?.nationality?.nationality
                        ? profilePersonalInfoData.nationality.nationality?.toUpperCase()
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TGIcon icon="divider-icon" fillColor="" size="100" />
            <div className={styles.myDetails}>
              <div className={styles.detailsInnerContainer}>
                <span className={styles.detailText}>
                  {t("label_inflight_preference")}
                </span>
              </div>
              {props.profileName == "ROP" && (
                <div className={styles.detailsInnerContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("profile_seat_pref")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {seatPreference}
                    </div>
                  </div>
                  <div className={styles.titleContainer}>
                    <div className={styles.detailsTextStyle}>
                      {t("profile_meal_pref")}
                    </div>
                    <div className={styles.detailsTextInfoStyle}>
                      {mealPreference}
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.titleContainer}>
                <div className={styles.detailsTextStyle}>
                  {t("label_language_preferences")}
                </div>
                <div className={styles.detailsTextInfoStyle}>
                  {isCrm
                    ? crmProfileResponse?.language
                      ? crmProfileResponse.language
                      : crmLanguage
                    : formattedLanguage}
                </div>
              </div>
            </div>
            <TGIcon icon="divider-icon" fillColor="" size="100" />
            <div className={styles.myDetails}>
              <div className={styles.detailsInnerContainer}>
                <span className={styles.detailText}>
                  {t("label_enroll_marketing_heading")}
                </span>
              </div>
              <div className={styles.marketingDetails}>
                {props.profileName == "ROP" && (
                  <>
                    <div className={styles.marketingDetailsText}>
                      {profileResponse.thaiOfferIndicator == "N" ? (
                        <TGIcon icon="cross-icon" fillColor="" size="" />
                      ) : (
                        <TGIcon icon="tick-icon" fillColor="" size="" />
                      )}
                      <span className={styles.text1}>
                        {t("label_enroll_primary_checkbox")}
                      </span>
                    </div>
                    <div className={styles.marketingDetailsText}>
                      {profileResponse.partnerOfferIndicator == "N" ? (
                        <TGIcon icon="cross-icon" fillColor="" size="" />
                      ) : (
                        <TGIcon icon="tick-icon" fillColor="" size="" />
                      )}
                      <span className={styles.text1}>
                        {t("label_enroll_secondary_checkbox")}
                      </span>
                    </div>
                  </>
                )}
                {props.profileName == "CRM" && (
                  <div className={styles.marketingDetailsText}>
                    {(
                      isCrm
                        ? crmProfileResponse?.subcription === false
                        : profileResponse.thaiOfferIndicator == "N"
                    ) ? (
                      <TGIcon icon="cross-icon" fillColor="" size="" />
                    ) : (
                      <TGIcon icon="tick-icon" fillColor="" size="" />
                    )}
                    <span className={styles.text1}>
                      {t("label_enroll_news_and_promotions_thai")}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <TGIcon icon="divider-icon" fillColor="" size="100" />
            <div className={styles.myDetails}>
              <div className={styles.detailsInnerContainer}>
                <span className={styles.detailText}>
                  {t("label_delete_account")}
                </span>
              </div>
              <div className={styles.deleteDescription}>
                {t("label_delete_account_description")}
              </div>
              <div className={styles.deleteButton}>
                <div
                  className={styles.deleteIconAndButton}
                  onClick={deleteAccountHandler}
                >
                  <TGIcon icon="delete-icon" fillColor="" size="" />
                  <span className={styles.deleteButtonText}>
                    {t("label_delete_account")}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles["modal-card-wrapper-mobile-view"]}>
              <TGModal
                show={deletePopUp}
                handleClose={deletePopUpCloseHandle}
                centered={true}
                heading={
                  !isDesktopView ? t("label_membership_cancellation") : ""
                }
                headingClass="modalHeadingTitle"
                customModalClass={styles["trip-card-model"]}
                customOffCanvasClass={styles["trip-card-offcanvas"]}
                backdropClassName={styles["trip-card-backdrop"]}
              >
                <div className={styles.tcModalFlex}>
                  <div className={styles.tcModal}>
                    <div className={styles.modalHeadingTitle}>
                      {t("label_membership_cancellation")}
                    </div>
                    <div className={styles.deletemodalContent}>
                      <div className={styles.deleteModalContentHeading}>
                        {t("label_account_closure")}
                      </div>
                      <div className={styles.deleteModalContentDescription}>
                        {deleteAccountPopUP?.listContents?.map((x: any) => (
                          <div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: x?.description,
                              }}
                              className={styles.deleteDescriptionWrapper}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={styles.dbuttonContainer}>
                    <TGButtonVariants
                      label="I Have Changed My Mind"
                      display="flex"
                      height={isDesktopView ? "61px" : "50px"}
                      padding={isDesktopView ? "8px 32px" : "12px 16px"}
                      justifyContent="center"
                      alignItems="center"
                      gap="8px"
                      borderRadius="30px"
                      boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                      fontSize="18px"
                      fontStyle="normal"
                      fontFamily="Inter"
                      fontWeight="700"
                      lineHeight="160%"
                      bgColor="#F5EFFF"
                      textColor="var(--primary-text, #684B9B)"
                      bgColorHover="#F5EFFF"
                      customClass="deleteButtons"
                      onClick={deletePopUpCloseHandle}
                      width={isDesktopView ? "341px" : "100%"}
                    />
                    <TGButtonVariants
                      label="Proceed"
                      display="flex"
                      height={isDesktopView ? "61px" : "50px"}
                      padding={isDesktopView ? "8px 32px" : "12px 16px"}
                      justifyContent="center"
                      alignItems="center"
                      gap="8px"
                      borderRadius="30px"
                      bgColor="#684B9B"
                      boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                      textColor="#FFFFFF"
                      fontFamily="Inter"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="160%"
                      bgColorHover="#684B9B"
                      customClass="deleteButtons"
                      onClick={proceedDeleteAccountHandler}
                      width={isDesktopView ? "192px" : "100%"}
                    />
                  </div>
                </div>
              </TGModal>
              <div className={styles.cta}></div>
            </div>
          </div>
        </>
      )}
      {/* Responsive is working fine for edit need to add remaining  fields as per figma and api integration as well*/}
      {isEditable && (
        <div className={styles.baseContainer}>
          <div className={styles.headerContainer}>
            {t("enroll_personal_Info")}
          </div>
          <div className={styles.bodyContainer}>
            <>
              <div className={styles.subHeading}>{t("profile_my_Details")}</div>
              <div className={styles.discrptionContainer}>
                {t("label_edit_note")}
              </div>
            </>

            <div className={styles.primaryDetailsContainer}>
              <div className={styles.primaryEnroll}>
                <div>
                  <TGSelection
                    isEnroll={true}
                    label={t("enroll_title")}
                    onChange={handleChange}
                    onSelect={(e, ob) => {
                      onSelect(e, ob);
                      setTitleState(e, ob);
                    }}
                    listData={[
                      { id: 1, listName: t("enroll_mr") },
                      { id: 2, listName: t("enroll_mrs") },
                      { id: 3, listName: t("enroll_ms") },
                      { id: 4, listName: t("enroll_mister") },
                      { id: 5, listName: t("enroll_miss") },
                      { id: 6, listName: t("enroll_title_prefer_not") },
                    ]}
                    name="title"
                    isLoading={false}
                    value={
                      isCrm ? formattedCrmSaluttation : formattedSaluttation
                    }
                    customClassName={styles.searchInput}
                    isReadOnly={true}
                    isError={!!crmErrors.title}
                    errorLabel={crmErrors.title}
                    disabled={isCrm ? false : true}
                  />
                </div>
                <div></div>
              </div>
              <div className={styles.primaryEnroll}>
                <div>
                  <TGInput
                    isEnroll={true}
                    label={t("enroll_firstname")}
                    value={
                      isCrm
                        ? `${crmProfileResponse.firstName} ${
                            crmProfileResponse?.middleName
                              ? crmProfileResponse?.middleName
                              : ""
                          }`
                        : `${profileResponse.firstName} ${
                            profileResponse?.middleName
                              ? profileResponse?.middleName
                              : ""
                          }`
                    }
                    // label={t("enroll_firstname")}
                    // value = {fullName?.toUpperCase()}
                    name="firstName"
                    onChange={handleChange}
                    customClassName={styles.fieldContainer}
                    validation="name"
                    maxLength={40}
                    disables={true}
                  />
                </div>
                {/* <div>
                  <TGInput
                    isEnroll={true}
                    label={t("profile_middle_name")}
                    value={
                      isCrm
                        ? crmProfileResponse.middleName
                        : profileResponse.middleName
                    }
                    name="middleName"
                    onChange={handleChange}
                    customClassName={styles.fieldContainer}
                    validation="name"
                    maxLength={40}
                    disables={true}
                  />
                </div> */}
                <div>
                  <TGInput
                    isEnroll={true}
                    label={t("enroll_lastName")}
                    value={
                      isCrm
                        ? crmProfileResponse.lastName
                        : profileResponse.lastName
                    }
                    name="lastName"
                    onChange={handleChange}
                    customClassName={styles.fieldContainer1}
                    validation="name"
                    maxLength={40}
                    disables={true}
                  />
                </div>
              </div>
              <div className={styles.primaryEnroll}>
                <div className={styles["profile-date"]}>
                  <TGDateDropdown
                    isEnroll={true}
                    disables={true}
                    isDesktop={isDesktopView}
                    label={t("label_reset_dob")}
                    savedDob={isCrm ? formattedCrmDob : formattedDob}
                    customClassName={styles.fieldWidth}
                    // isError={!!errors.dob}
                    onDateChange={handleDateChange}
                    // errorLabel={errors.dob}
                  />
                </div>
                <div>
                  <TGSelection
                    isEnroll={true}
                    label={t("enroll_gender")}
                    onChange={handleChange}
                    onSelect={(e, ob) => {
                      onSelect(e, ob);
                      setGenderState(e, ob);
                    }}
                    listData={[
                      { id: 1, listName: t("enroll_male") },
                      { id: 2, listName: t("enroll_female") },
                      // { id: 3, listName: t("enroll_Unspecified") },
                      { id: 3, listName: t("enroll_undisclosed") },
                    ]}
                    name="gender"
                    isError={
                      isCrm && crmErrors?.gender == "error" ? true : false
                    }
                    errorLabel={t("enroll_gender_error")}
                    isLoading={false}
                    value={
                      isCrm ? crmProfileResponse?.gender : formattedData.gender
                    }
                    customClassName={styles.fieldWidth}
                    isReadOnly={
                      (isCrm && crmProfileResponse?.gender) || !isCrm
                        ? true
                        : false
                    }
                    disabled={isCrm ? false : true}
                  />
                </div>
              </div>
              <div className={styles.primaryEnrollNationality}>
                <div className={styles.nationalityContainer}>
                  <div>
                    <Form.Group>
                      <Form.Label className={styles.preferredNewsDropDownLabel}>
                        {t("profile_nationality")}
                      </Form.Label>
                      <TGTypeahead
                        id="preferredCountry"
                        labelKey={(option: any) =>
                          `${isCrm ? option : option.nationality}`
                        }
                        options={isCrm ? crmNationalityList : countryData}
                        disabled={
                          isCrm
                            ? false
                            : nationality?.nationality == "" ||
                              nationality?.nationality == undefined
                            ? false
                            : true
                        }
                        onChange={handleNationalityChange}
                        placeholder={
                          isCrm
                            ? crmProfileResponse?.nationality?.toUpperCase()
                            : selectedNationalityValue !== null
                            ? selectedNationalityValue?.nationality?.toUpperCase()
                            : nationality?.nationality?.toUpperCase()
                        }
                        selected={
                          selectedNationalityValue
                            ? [selectedNationalityValue]
                            : []
                        }
                        isOpen={isToggleOpen === "preferredCountry"}
                        setIsOpen={(id) => setIsToggleOpen(id)}
                      />
                      {(isCrm ? crmErrors.nationality : errors.nationality) && (
                        <Form.Label className={styles.errorLabel}>
                          {t("label_nationality_required")}
                        </Form.Label>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className={styles["profile-border"]}></div>
            {/* <TGIcon icon="divider-icon" fillColor="" size="" /> */}
            <div className={styles.primaryDetailsContainer}>
              <h4 className={styles.detailText}>
                {t("label_inflight_preference")}
              </h4>
              {props.profileName == "ROP" && (
                <div className={styles.primaryEnroll}>
                  <div>
                    <TGSelection
                      isEnroll={true}
                      customClassName={styles.fieldWidth}
                      savedValue={
                        location.state
                          ? location.state.enrollmentData?.seatPreferenceValue
                          : ""
                      }
                      label={t("label_seat_preference")}
                      onChange={handleChange}
                      onSelect={(e, ob) => {
                        onSelect(e, ob);
                        setSeatState(e, ob);
                      }}
                      listData={[
                        { id: 1, listName: "Aisle", listAbbr: "NSSA" },
                        { id: 2, listName: "Window", listAbbr: "NSSW" },
                        { id: 3, listName: "No Preference", listAbbr: "" },
                      ]}
                      name="seatPreference"
                      isLoading={false}
                      forceClose={forceClose}
                      isReadOnly={true}
                      displayDefaultValue={true}
                      defaultValue={seatPreference}
                    />
                  </div>
                  <div>
                    <TGSelection
                      isEnroll={true}
                      customClassName={styles.fieldWidth}
                      label={t("label_meal_preferences")}
                      onChange={handleChange}
                      savedValue={
                        location.state
                          ? location.state.enrollmentData?.mealPreferenceValue
                          : ""
                      }
                      onSelect={(e, ob) => {
                        onSelect(e, ob);
                        setMealState(e, ob);
                      }}
                      listData={mealData}
                      name="mealPreference"
                      isLoading={false}
                      forceClose={forceClose}
                      displayDefaultValue={true}
                      defaultValue={mealPreference}
                      isReadOnly={true}
                    />
                  </div>
                </div>
              )}
              <div className={styles.primaryEnroll}>
                <div>
                  <TGSelection
                    isEnroll={true}
                    savedValue={
                      location.state
                        ? location.state.enrollmentData?.languagePreferenceValue
                        : ""
                    }
                    customClassName={styles.lanFieldContainer}
                    label={t("label_language_preferences")}
                    onChange={handleChange}
                    onSelect={(e, ob) => {
                      onSelect(e, ob);
                      setLanguageState(e, ob);
                    }}
                    listData={[
                      { id: 1, listName: "English", listAbbr: "E" },
                      { id: 2, listName: "Thai", listAbbr: "T" },
                    ]}
                    name="language"
                    isLoading={false}
                    forceClose={forceClose}
                    displayDefaultValue={true}
                    defaultValue={isCrm ? crmLanguage : formattedLanguage}
                    isReadOnly={true}
                  />
                </div>
              </div>
            </div>
            {/* <TGIcon icon="divider-icon" fillColor="" size="" /> */}
            {props.profileName == "ROP" && (
              <div className={styles.referingEnroll}>
                <div className={styles.heading}>
                  {t("label_enroll_marketing_heading")}
                </div>
                <div className={styles.marketingDescription}>
                  {t("label_enroll_marketing_desc")}
                </div>
              </div>
            )}
            {!isLessThanSixteen && props.profileName === "CRM" && (
              <div className={styles.referingEnroll}>
                <div className={styles.heading}>
                  {t("label_enroll_marketing_heading")}
                </div>
                <div className={styles.marketingDescription}>
                  {t("label_enroll_marketing_desc")}
                </div>
              </div>
            )}

            <div className={styles.marketingContainer}>
              {props.profileName == "ROP" && (
                <div className={styles.agreeContainer}>
                  <TGCheckBox
                    label={t("label_enroll_primary_checkbox")}
                    onChange={checkedPrimaryChange}
                    checked={isPrimaryChecked}
                    customLabel="checkLabel"
                    className="checkLabel"
                  />

                  {/* {!isPreferenceDisabled && (
                    <div className={styles.preferredNewsDropDownContainer}>
                      <Form.Group>
                        <Form.Label
                          className={styles.preferredNewsDropDownLabel}
                        >
                          {t("label_preferred_news_rop")}
                        </Form.Label>
                        <Typeahead
                          id="preferredCountry"
                          labelKey={(option: any) => `${option.country}`}
                          options={preferenceFromResponse}
                          onChange={handlePreferredCountryChange}
                          placeholder={
                            selectedCountryValue !== null
                              ? selectedCountryValue?.country
                              : ""
                          }
                          selected={
                            selectPreferenceCountry
                              ? [selectPreferenceCountry]
                              : []
                          }
                          disabled={isPreferenceDisabled ? true : false}
                          renderMenuItemChildren={(option: any) => (
                            <div>
                              <span>{option.country}</span>
                            </div>
                          )}
                          renderInput={({
                            inputRef,
                            referenceElementRef,
                            ...inputProps
                          }) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              {
                                <>
                                  {selectPreferenceCountry && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: "10px",
                                        pointerEvents: "none",
                                      }}
                                    ></div>
                                  )}
                                  <Form.Control
                                    {...inputProps}
                                    onKeyDown={handleKeyDown}
                                    ref={(node: HTMLInputElement) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                    name="country"
                                    value={
                                      selectPreferenceCountry
                                        ? selectPreferenceCountry.country
                                        : (inputProps.value as string)
                                    }
                                    style={{
                                      flex: 1,
                                      paddingLeft: "12px",
                                    }}
                                    className={`${styles["tg-select-box"]}`}
                                  ></Form.Control>
                                  {/* {errors.preference && !isPreferenceDisabled && (
                                  <div
                                    className={styles.errorIcon}
                                    style={{
                                      position: "absolute",
                                      right: "30px",
                                      pointerEvents: "none",
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <TGIcon
                                      icon="error-icon"
                                      fillColor=""
                                      size="16"
                                    />
                                  </div>
                                )} */}
                  {/* <div
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      pointerEvents: "none",
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <TGIcon
                                      icon="chevron-down-icon"
                                      fillColor=""
                                      size="16"
                                    />
                                  </div> */}
                  {/* </>
                              }
                            </div>
                          )} */}
                  {/* ></Typeahead>
                        {/* {errors.preference && !isPreferenceDisabled && (
                        <Form.Label className={styles.errorLabel}>
                          {t("label_preferred_news_rop_err_msg")}
                        </Form.Label>
                      )} */}
                  {/* </Form.Group> */}
                  {/* </div> */}
                  {/* )}   */}

                  <TGCheckBox
                    label={t("label_enroll_secondary_checkbox")}
                    onChange={checkedSecondaryChange}
                    checked={isSecondaryChecked}
                    className="checkLabel"
                    customLabel="checkLabel"
                  />
                  {isLessThanSixteen &&
                    (isSecondaryChecked || isPrimaryChecked) && (
                      <div className={styles.parentConsent}>
                        <div className={styles.parentContainer}>
                          <span className={styles.parentTextHeading}>
                            {t("label_enrolment_consent_parent_info_title")}{" "}
                          </span>
                          <p className={styles.parentDesc}>
                            {t(
                              "label_enrolment_crm_consent_parent_description"
                            )}
                          </p>
                        </div>
                        <div className={styles.inputParentContainer}>
                          <div className={styles.inputParentSubContainer}>
                            <TGInput
                              isEnroll={true}
                              label={t("label_reset_first_name")}
                              value={
                                profilePersonalInfoData.consentParentFirstName
                              }
                              name="consentParentFirstName"
                              onChange={handleChange}
                              customClassName={styles.fieldContainer}
                              validation="name"
                              maxLength={40}
                              isError={!!errors.consentParentFirstName}
                              errorLabel={
                                !!errors.consentParentFirstName &&
                                profilePersonalInfoData?.consentParentFirstName
                                  ?.length === 0
                                  ? t("error_parent_middle_name_required")
                                  : t("error_min_length_first_name")
                              }
                            />
                            <TGInput
                              isEnroll={true}
                              label={t("label_manage_last_name")}
                              value={
                                profilePersonalInfoData.consentParentLastName
                              }
                              name="consentParentLastName"
                              onChange={handleChange}
                              customClassName={styles.fieldContainer}
                              validation="name"
                              maxLength={40}
                              isError={!!errors.consentParentLastName}
                              errorLabel={
                                !!errors.consentParentLastName &&
                                profilePersonalInfoData?.consentParentLastName
                                  ?.length === 0
                                  ? t("error_reset_last_name_required")
                                  : t("error_min_length_last_name")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
              {!isLessThanSixteen && isCrm && (
                <div className={styles.agreeContainerCrm}>
                  <TGCheckBox
                    label={t("label_enroll_news_and_promotions_thai")}
                    onChange={checkedCrmChange}
                    checked={isCrmSubscribed}
                    className="checkLabel"
                    customLabel="checkLabel"
                  />

{isCrmSubscribed && (
                   <div className={styles.preferredNewsDropDownContainer}>
                     <Form.Group
                       style={
                         isDesktopView ? { width: "50%" } : { width: "100%" }
                       }
                     >
                       <Form.Label className={styles.preferredNewsDropDownLabel}>
                         {t("label_preferred_news_rop")}
                       </Form.Label>
                       <TGTypeahead
                         className={styles.wrapper}
                         id="newsPreferredCountry"
                         labelKey={(option: any) => `${option.country}`}
                         options={newsletterPreferenceResponse}
                         onChange={handlePrefCountryChange}
                         isOpen={isToggleOpen === "newsPreferredCountry"}
                         setIsOpen={(id) => setIsToggleOpen(id)}
                         placeholder={
                           // crmProfileResponse?.preferredNews ?crmProfileResponse?.preferredNews :
                           selectedCountryValue !== null
                             ? selectedCountryValue?.country?.toUpperCase()
                             : ""
                         }
                         selected={selectedPreferedCountry
                             ? [selectedPreferedCountry]
                             : []
                         }
                     
                       />
                       {errors.preference && !isDisabled && (
                         <Form.Label className={styles.errorLabel}>
                           {t("label_preferred_news_rop_err_msg")}
                         </Form.Label>
                       )}
                     </Form.Group>
                     <Form.Group
                       style={
                         isDesktopView ? { width: "50%" } : { width: "100%" }
                       }
                     >
                       <Form.Label className={styles.preferredNewsDropDownLabel}>
                         {t("label_country_of_residence")}
                       </Form.Label>
                       <Typeahead
                       id="country"
                       labelKey={(option: any) => `${option.name}`}
                       options={residenceResponse}
                       onChange={handleCountryChange}
                       placeholder={
                         // crmProfileResponse?.country ?crmProfileResponse?.country :
                         selectedCntryValue !== null
                           ? selectedCntryValue?.name?.toUpperCase()
                           : ""
                       }
                       selected={selectedCountry ? [selectedCountry] : []}
                       open={isToggleOpen === "country"}
                       onBlur={() => setIsToggleOpen("")}
                       renderMenuItemChildren={(option: any) => (
                         <div>
                           <img
                             src={option.image}
                             // alt={option.name}
                             style={{ width: "30px", marginRight: "10px" }}
                           ></img>
                           <span>{option.name?.toUpperCase()}</span>
                         </div>
                       )}
                       renderInput={({
                         inputRef,
                         referenceElementRef,
                         ...inputProps
                       }) => (
                         <div
                           style={{
                             display: "flex",
                             alignItems: "center",
                             position: "relative",
                           }}
                         >
                           {
                             <>
 
                               {selectedCntryValue && (
                                 <div
                                   style={{
                                     position: "absolute",
                                     left: "6px",
                                     pointerEvents: "none",
                                   }}
                                 >
                                   
                                   <img
                                     src={selectedCntryValue?.image}
                                     // alt={selectedCntryValue?.name}
                                     style={{ width: "30px", height: "20px" }}
                                   ></img>
                                 </div>
                               )}
                               <Form.Control
                                 {...inputProps}
                                 ref={(node: HTMLInputElement) => {
                                   inputRef(node);
                                   referenceElementRef(node);
                                 }}
                                 name="country"
                                 onKeyDown={handleKeyDown}
                                 value={
                                   selectedCountry
                                     ? selectedCountry?.name?.toUpperCase()
                                     : (inputProps.value?.toUpperCase() as string)
                                 }
                                 style={{
                                   flex: 1,
                                   paddingLeft: selectedCntryValue
                                     ? "40px"
                                     : "12px",
                                 }}
                                 className={`${styles["tg-select-box"]}`}
                                 onClick={() =>
                                   isToggleOpen === "country"
                                     ? setIsToggleOpen("")
                                     : setIsToggleOpen("country")
                                 }
                               ></Form.Control>
                               {errors.country && (
                                 <div
                                   className={styles.errorIcon}
                                   style={{
                                     position: "absolute",
                                     right: "30px",
                                     pointerEvents: "none",
                                     color: "#000",
                                     fontSize: "16px",
                                   }}
                                 >
                                   <TGIcon
                                     icon="error-icon"
                                     fillColor=""
                                     size="16"
                                   />
                                 </div>
                               )}
                               <div
                                 style={{
                                   position: "absolute",
                                   right: "10px",
                                   pointerEvents: "none",
                                   color: "#000",
                                   fontSize: "16px",
                                 }}
                               >
                                 <TGIcon
                                   icon="chevron-down-icon"
                                   fillColor=""
                                   size="16"
                                 />
                               </div>
                             </>
                           }
                         </div>
                       )}
                     ></Typeahead>
                       {errors.country && (
                         <Form.Label className={styles.errorLabel}>
                           {t("label_country_of_residence_req")}
                         </Form.Label>
                       )}
                     </Form.Group>
                   </div>
               )}
                  
                </div>
              )}
              <div
                className={`${styles["profile-border"]} ${styles["fieldWidth"]}`}
              ></div>
              <div
                className={
                  isDesktopView
                    ? styles.personalInfoacknowledgementContainer
                    : styles.personalInfoacknowledgementMobileContainer
                }
              >
                <div
                  className={
                    isDesktopView
                      ? styles.personalInfoacknowledgement
                      : styles.personalInfoacknowledgementMobile
                  }
                >
                  <div className={styles.checkTermsConditions}>
                    <TGCheckBox
                      isHtmlContent={true}
                      htmlContent={termConditionData.termConditionHeadingtext}
                      onChange={checkedChange}
                      checkref={termConditionRef}
                      className="checkLabel"
                      customLabel="checkLabel"
                    />
                    {!isDesktopView &&
                      (isCrm
                        ? crmErrors.termsConditions
                        : errors.termsConditions) &&
                      renderTermsConditionError()}
                    {!isDesktopView && renderSaveButton()}
                    <div className={styles.PrivacyNoticeContainer}>
                      {
                        <span
                          className={styles.personalInfoPrivacyNoticeText}
                          dangerouslySetInnerHTML={{
                            __html: termConditionData.privacyNotice,
                          }}
                        ></span>
                      }
                    </div>

                    {isDesktopView &&
                      (isCrm
                        ? crmErrors.termsConditions
                        : errors.termsConditions) &&
                      renderTermsConditionError()}
                  </div>
                </div>

                {isDesktopView && (
                  <div className={styles.submitProfContactInfoButton}>
                    {isDesktopView && renderSaveButton()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
